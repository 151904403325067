import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

 

import {
  getStorageList as getStorageListAction,
  getStorageListByCat as getStorageListByCatAction,
  addStorage as addStorageAction,
  update as updateStorageAction,
  searchStorage as searchStorageAction
} from "../../redux/modules/storage";

import {
  convertImages,  toArrayKeyLabelListKey,
  inArrayValueByKey,
} from "../../utilities/array";

 
import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiDropDown from "../../components/inputs/UiDropDown";
import UiModalProductAdd from "../../components/modals/UiModalProductAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ProductsStyle.scss";
import Env from "../../services/Env";
import { retrieveData, storeData } from "../../services/Storage";

function ProductsPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);
  const [searchLine, setSearchLine] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);

  const [storageList, setStorageList] = useState([]);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: false, title: "" },
    { sort: true, title: "Сортировка" },
    { sort: false, title: "Название" },
    { sort: true, title: "Категория" },
    { sort: true, title: "Цена" },
    { sort: false, title: "Доступно к заказу" }, 
    { sort: false, title: "Скрыть" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  function _load() {
    let search = retrieveData("SEARCH_LINE");

    if (id == "all") {
      props.getStorageList().then((data) => {
        if (data) setStorageList(data);
        if(search)     _search(search)
      });
    } else {
      props.getStorageListByCat(id).then((data) => {
        if (data) setStorageList(data);
        if(search)     _search(search)
      });
    } 


  }

  function _search(_searchLine){
    setSearchLine(_searchLine);
    storeData("SEARCH_LINE", _searchLine);
    props.searchStorage(_searchLine).then((data) => {
      if (data) setStorageList(data);
    });
  }

  useEffect(() => {
    _load();
  }, [id]);

  useEffect(() => {
    if(props.category.list.length > 0) setCategoriesList(props.category.list);
  }, [props.category]);

  useEffect(() => {
    if(searchLine != "") {
      _search(searchLine)
    } else {
      storeData("SEARCH_LINE", null);
      _load()
    }
  }, [searchLine])


  useEffect(() => {
    let arr = [];
    storageList.map((item) => {
      let images = convertImages(item.imgs);
      item.images = images;
      arr.push([
        {
          button: "edit",
          onClick: () => {
            storeData("edit", item);
            navigate("/products/add");
          },
        },
        { image: images[0] ? Env.PUBLIC_URL + images[0] : null },
        {
          inputSort: `${item.sort}`,
          onBlurInput: (v) => {
            let _item = item;
            let s = _item.sort;
            if (v == 1) {
              s++;
              _item.sort = s;
            } else {
              s--;
              _item.sort = s;
            }
            console.log(s);
            props.update(_item).then(() => _load());
          },
        },
        { text: item.title },
        {
          statusInfo:
            props.category.list.length > 0
              ? inArrayValueByKey(props.category.list, "id", item.category)
                  ?.title
              : "",
          statusColor: "orange",
        },
        { text: `${item.price} ₽` },
        {
          button: item.is_available != 0 ? "eye-open" : "eye",
          color: item.is_available == 1 ? "teal" : "gray",
          onClick: () => {
            let _item = item;
            _item.is_available = item.is_available == 1 ? 0 : 1;
            props.update(_item).then(() => _load());
          },
        },

       
        {
          button: item.is_active != 0 ? "eye-open" : "eye",
          color: item.is_active != 0 ? "teal" : "gray",
          onClick: () => {
            let _item = item;
            _item.is_active = item.is_active == 1 ? 0 : 1;
            props.update(_item).then(() => _load());
          },
        },
        {
          button: "delete",
          color: "red",
          onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props.update(_item).then(() => _load());
            }
          },
        },
      ]);
    });
    setTableListOrders(arr);
  }, [storageList]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
        <UiPageText
            title={
              id !== "all"
                ? `Каталог товаров в категории: ${
                  categoriesList.length > 0
                      ? inArrayValueByKey(categoriesList, "id", id)?.title
                      : ""
                  }`
                : `Каталог товаров`
            }
          />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="indigo"
            text="Добавить"
            small={true}
            onClick={() => {
              storeData("edit", null);
              navigate("/products/add");
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            placeholder="Поиск:"
            value={searchLine}
            onChange={(val) => setSearchLine(val)}
          />
        </div>

        <div className="page-search">
          <UiDropDown
            label="Фильтр по категории:"
            value={id}
            items={toArrayKeyLabelListKey(
              categoriesList ? categoriesList : [],
              "id",
              "title",
              true
            )}
            onChange={(val) => {
              navigate(
                val ? `/products/category/${val}` : `/products/category/all`
              );
            }}
          />
        </div>
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders} 
        />
      </section>
      {/* modal deal add */}
      <UiModalProductAdd
        modalActive={modalDealAddACtive}
        modalClose={() => setModalDealAddActive(false)}
        findUserEvent={props.getUsersByPhone}
        prices={props.settings.prices}
        onCreate={(data) => {
          props.addOrder(data).then(() => {
            setModalDealAddActive(false);
          });
        }}
      />
    </div>
  );
}

export default connect(
  ({ storage, login, comment, logs, user, settings, category }) => ({
    storage,
    login,
    comment,
    logs,
    user,
    settings,
    category,
  }),
  {
    getStorageList: getStorageListAction,
    getStorageListByCat: getStorageListByCatAction,
    addStorage: addStorageAction,
    update: updateStorageAction,
    searchStorage: searchStorageAction
  }
)(ProductsPage);
